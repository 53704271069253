<template>
  <div class="columns has-background-white">
    <div class="column">    
      <h1 class="title is-size-5">Create a free account.</h1>
      <form @submit.prevent="onSubmit">
        <BaseInput
          label="First and Last Name" 
          type="text"
          v-model.trim="$v.user.name.$model"
          :validation="$v.user.name"
        />
        <BaseInput
          label="Email" 
          type="email"
          v-model.trim="$v.user.email.$model"
          :validation="$v.user.email"
        />
        <BaseInput
          label="Create Password (min 8 characters)"
          type="password"
          v-model="$v.user.password.$model"
          :validation="$v.user.password"
        />
        <BaseSubmit
          label="Sign up"
          :error="error"
          :disabled="$v.$invalid"
          :submitting="submitting"
        />
      </form>
      <p class="subtitle is-size-6" style="margin-top:10px;">
        Already member? <a @click="go('/login')">Login</a>
      </p>
    </div>
    <div class="column has-background-info has-text-white" style="padding:40px;">
      <h1 class="title has-text-white"><Brand /></h1>
      <p class="subtitle has-text-white">{{ displayText }}</p>
    </div>
  </div>
</template>

<script>
import MarketReferrerMixin from "@/mixins/MarketReferrerMixin.js";
import { email, minLength, required } from 'vuelidate/lib/validators';
import Brand from "@/components/Brand.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";

export default {
  mixins: [MarketReferrerMixin],
  components: {
    Brand,
    BaseInput,
    BaseSubmit
  },
  data() {
    return {
      submitting: false,
      error: null,
      user: {
        name: '',
        email: '',
        password: ''
      }
    }
  },
  computed: {
    isMerchant: function() {
      return (this.$route.query.t == "merchant") ? true : false
    },
    nextPath: function() {
      return (this.$route.query.t) ? `/subscribe?t=${this.$route.query.t}` : "/subscribe"
    },
    displayText: function() {
      if (this.isMerchant) {
        return "Helps you attract customers by rewarding them."
      } else {
        return "Discover the best local deals."
      }
    }
  },
  validations: {
     user: {
      name: { required },
      email: { required, email},
      password: { required, minLength: minLength(8) }
    }
  },
  methods: {
    go(path) {
      this.$router.push(path);
    },
    onSubmit() {
      this.submitting = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const params = {
        name: this.user.name,
        email: this.user.email,
        password: this.user.password
      }
      this.$store.dispatch("signup", params)
        .then(() => {
          this.submitting = false
          if (window.NativeScript) {
            window.NativeScript.setLoginState(true);
          }
          this.go(this.nextPath);
        })
        .catch(error => {
           this.submitting = false
           this.error = error
        })
    }
  },
  mounted() {
    this.updateMarketReferral()

    if (this.$store.getters.isLoggedIn) {
      this.go("/")
    } else if (window.NativeScript) {
      window.NativeScript.setLoginState(false);
    }
  },
}
</script>
